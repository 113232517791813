import React from "react";
import Container from "@mui/material/Container";
import ResponsiveAppBar from "../components/ResponsiveAppBar";

type Props = {
  children: JSX.Element;
};

const DashboardLayout = ({ children }: Props) => {
  //   console.log("render Dashboard Layout");
  return (
    <div className="antialiased">
      <ResponsiveAppBar />
      <Container maxWidth="xl">{children}</Container>
    </div>
  );
};

export default DashboardLayout;
