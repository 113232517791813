import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const getEnv = () => {
  if (document.URL.indexOf("localhost")) return "local";
  if (document.URL.indexOf(".lab.")) return "sandbox";
  if (document.URL.indexOf(".dev.")) return "development";
  if (document.URL.indexOf(".stg.")) return "staging";
  if (document.URL.indexOf("one.hoag.io")) return "production";
  return false;
};

const env = getEnv();

if (env) {
  Sentry.init({
    dsn: "https://06511fd6320d435a9129533992384f72@o976172.ingest.sentry.io/4504601054347264",
    integrations: [new BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: env === "production" ? 0.05 : 1.0,
    environment: env,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: env === "production" ? 0.01 : 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Router>
    <App />
  </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
