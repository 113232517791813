export const createInvite = async (
  name,
  method,
  address,
  accessToken,
  idToken
) => {
  const url = `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/p/api/invite`;
  const requestBody = {
    name,
    method,
    address,
  };
  const res = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: "Bearer " + accessToken,
      "X-OpenID": idToken,
    },
    body: JSON.stringify(requestBody),
  });
  const data = await res.json();
  return data;
};
