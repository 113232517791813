import { Route, useHistory } from "react-router-dom";
import { SecureRoute, Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Dashboard from "./layouts/Dashboard";
import FileRequest from "./modules/FileRequest";
import "./App.css";

const oktaAuth = new OktaAuth({
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ["openid", "profile", "email", "offline_access"],
  pkce: true,
  postLogoutRedirectUri: `${window.location.origin}/logout`,
});

const theme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
    fontFamily: [
      "CircularXX",
      '"Helvetica Neue"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "white",
        },
      },
    },
  },
});

function App() {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <ThemeProvider theme={theme}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Route path="/unauthorized" component={ErrorNotAuthorized} />
        <Route path={["/file-request"]}>
          <Dashboard>
            <SecureRoute path="/file-request" component={FileRequest} />
          </Dashboard>
        </Route>
        <Route
          path="/login/callback"
          component={() => <LoginCallback errorComponent={ErrorRedirect} />}
        />
        <Route path="/logout" component={Logout} />
        <Route path="/" exact={true} component={ErrorNotFound} />
      </Security>
    </ThemeProvider>
  );
}

function ErrorRedirect() {
  return <></>;
}
function ErrorNotFound() {
  return <></>;
}
function ErrorNotAuthorized() {
  return <></>;
}
function Logout() {
  return (
    <div>
      You have been successfully logged out. Please close the window/tab.
    </div>
  );
}

export default App;
